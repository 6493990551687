
@import url('https://fonts.googleapis.com/css2?family=Lora:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;500;600;700&display=swap');

$base-font: 'Work Sans', sans-serif;
$title-font: 'Work Sans', serif;
$title-color: #7F9E56;
$button-color: #527246;
$main-color: #181E1A;
$text-color: #969F9A;

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

a, ul {
    list-style: none;
    text-decoration: none;
    color: $text-color;
}

html {
    transition: all 0.3s ease-in-out;
    scroll-behavior: smooth;
}

body {
    font-family: 'Work Sans', sans-serif;
    color: $text-color;
}

.wrapper {
    margin: 0 80px;
}

.form-input {
    width: 405px;
    height: 65px;
    padding: 0 20px;
    align-items: center;
    border: 1px solid $text-color;
    border-radius: 8px;
    background-color: #fff;
    font-size: 16px;
    font-family: 'Work Sans', sans-serif;
    color: $text-color;
    font-weight: 400;
    line-height: 24px;

    &:hover {
        border: 1px solid #181E1A;
    }
}

.main-button {
    width: 350px;
    height: 65px;
    border: none;
    border-radius: 100px;
    background-color: $button-color;
    color: #fff;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    font-family: 'Work Sans', sans-serif;
    transition: all 0.1s ease-in-out;
    cursor: pointer;

    &:hover {
        background-color: $title-color;
    }
}

.main-title {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 34px;
    font-weight: 600;
    line-height: 38px;
    color: $main-color;
    letter-spacing: -0.35px;
    text-align: center;
}

.map-title {
    padding-bottom: 52px;
}

.burger {
    display: none;
}

svg {
    min-width: 24px;
    min-height: 24px;
}

.text-icon {
    width: 24px;
    height: 24px;
}

// ============ adaptive ==================

@media screen and (max-width: 1200px) {
    .wrapper {
        margin: 0 16px;
    }
    
    .header__items {
        display: none;
    }

    .complex__info {
        margin: 0 !important;
    }

    .burger {
        display: block;
        
        &__container {
            margin: 0 55px;
        }

        &__menu {
            padding-top: 35px;
            padding-bottom: 200px;
            background-color: #161B18;
            position: fixed;
            transition: transform 0.2s;
            transform: translateX(-100%);
            left: 0;
            right: 0;
            z-index: 99;
            overflow: scroll;


            &-title {
                font-family: $base-font;
                font-size: 20px;
                font-weight: 500;
                line-height: 24px;
                color: #fff;
            }

            &-actions {
                padding-top: 40px;
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 20px;

                .burger-input {
                    width: 100%;
                    height: 50px;
                }

                .burger-button {
                    width: 100%;
                    height: 50px;
                }
            }

            &-contacts {
                padding-top: 65px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
            }

            .contacts__container {
                display: flex;
                flex-direction: column;
                gap: 20px;

                &-title {
                    font-family: $base-font;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 24px;
                    color: $text-color;
                }

                &-text {
                    font-family: $base-font;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 24px;
                    color: #fff;
                    display: flex;
                    align-items: center;
                    gap: 8px;

                    &-span {
                        background-color: #FFEC8B;
                        padding: 1px 9px;
                        font-size: 14px;
                        color: #181E1A;
                        border: 1px solid #FFEC8B;
                        border-radius: 20px;
                    }
                }
            }
        }

        .showMenu {
            transform: translateX(0);
        }
    }
    
    .burger__items {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 16px;

        .humburger {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &-icon {
            position: fixed;
            left: 0;
            padding-left: 55px;
            cursor: pointer;
        }

        .closeIcon {
            display: none;
        }
    }

    .footer {
        .content__item {
            &-logos{
                flex-direction: column;
                align-items: center;
            }
        }  
    }

}

@media screen and (max-width: 1024px) {

    .wrapper {
        margin: 0 35px;
    }

    .footer__content {
        .content__item-text {
            font-size: 10px;
        }
    }

    .complexes {
        margin-top: 80px;

        &__title {
            font-size: 32px;
            line-height: 36px;
            font-weight: 500;
        }

        &__items {
            margin-top: 50px;
        }

        .complex__info {

            &-slider {
                width: 800px;
                height: 620px;
            }

            &-text {
                width: 800px;
                height: 100%;
                gap: 10px;

                .info__text {
                    font-size: 18px;
                    line-height: 24px;
                }
            }
            .text-container {
                gap: 15px;
            }

            .sizes-container {
                flex-direction: column !important;
                gap: 10px !important;
            }
        }

        .complex__base-card {
            position: relative;
            transform: translateX(0);
        }

        .info-text__extra {
            display: block !important;
        }
    }
    .complex__info {
        flex-direction: column !important;
        align-items: center;

        &-text {
            border-radius: 0 0 20px 20px !important;
        }
    }

    
    .footer {
        &__content-items {
            gap: 30px;
        }
        .content__item {

            &-logo {
                width: 150px;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .main-title {
        font-size: 28px;
    }

    .wrapper {
        margin: 0 56px;
    }

    .complexes {
        margin-top: 40px;

        &__title {
            font-size: 26px;
            line-height: 32px;
        }

        &__items {
            margin-top: 32px;
        }

        .complex {
            &__info {

                &-slider {
                    max-width: 670px;
                    height: auto;
                }
                &-text {
                    max-width: 670px;
                    height: auto;
                    padding: 16px 16px 70px 16px;
                }

            }

            .form {
                &__container {
                    margin: 0 16px;
                }
            }
        }
    }

    .ny {
        flex-direction: column;
        background-image: url('./assets/images/ny-bg-tablet.png');

        &-info {
            &__title {
                font-size: 26px;
                line-height: 25px;
            }
            &__text {
                font-size: 22px;
            }
        }
    }

    .footer {
        &__content-items {
            flex-direction: column;

            .footer__logo {
                align-items: center;
            }

            .content__item {
                &-logo {
                    width: 200px;
                    height: 120px;
                }

                &-logos {
                    flex-direction: row;
                }

                &-title {
                    font-size: 14px;
                    line-height: 20px;
                }

                &-text {
                    font-size: 14px;
                    line-height: 24px;
                }
            }
        }
    }
}

@media screen and (max-width: 620px) {
    .wrapper {
        margin: 0 16px;
    }

    .complexes {
        .complex {
            &__info {
    
                &-slider {
                    max-width: 520px;
                    height: auto;
                }
                &-text {
                    max-width: 520px;
                    height: auto;
                    padding: 16px 16px 70px 16px;
                }
    
            }
    
            .form {
                &__container {
                    margin: 16px 16px;
                }
            }
        }
    }
}

@media screen and (max-width: 480px) {
    .main-title {
        font-size: 23px;
        margin: 0;
    }

    .burger {
        &__menu {
            padding-bottom: 50px;
            height: 100%;

            &-title {
                text-align: center;
            }

            &-actions {
                flex-direction: column;
                padding-top: 32px;
                gap: 32px;
            }

            &-contacts {
                padding: 32px 0;
                flex-direction: column;
                gap: 32px;
            }
        }
        &__container {
            margin: 0 16px;
        }

        &__socials {
            border-bottom: none !important;
        }
    }

    .burger__items {
        justify-content: space-between;

        &-icon {
            padding-left: 16px;
        }
    }

    .header__items-logo {
        width: 70px;
        height: 40px;
    }

    .complexes {
        margin-top: 40px;
        margin-bottom: 40px;

        &__title {
            font-size: 26px;
            line-height: 32px;
        }

        &__items {
            margin-top: 32px;
        }

        .complex {
            &__info {

                &-slider {
                    max-width: 375px;
                    height: auto;
                }
                &-text {
                    max-width: 375px;
                    height: auto;
                    padding: 16px 16px 70px 16px;
                }

            }

            .form {
                &__container {
                    margin: 0;
                    &-title {
                        font-size: 26px;
                        line-height: 32px;
                    }

                    &-actions {
                        .form-input {
                            width: 290px;
                            height: 50px;
                        }

                        .form-button {
                            width: 290px;
                            height: 50px;
                        }
                    }
                }
            }
        }
    }

    .footer {
        &__content-items {
            flex-direction: column;
            border: none;

            .footer__logo {
                align-items: center;
            }

            .content__item {
                &-logo {
                    width: 200px;
                    height: 120px;
                }

                &-title {
                    font-size: 14px;
                    line-height: 20px;
                }

                &-text {
                    font-size: 14px;
                    line-height: 24px;
                }
            }
        }
        
        .number-text {
            width: 100%;
        }

        &__actions {
            flex-direction: column-reverse;
            gap: 20px;
            padding-top: 0;

            &-socials {
                border-bottom: 1px solid #fff;
                padding-bottom: 20px;
            }
        }
    }
}